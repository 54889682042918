import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/user/Login.vue'),
  },
  {
    path: '/user/register',
    name: 'register',
    component: () => import('../views/user/register.vue'),
  },
  {
    path: '/user/index',
    name: 'index',
    component: () => import('../views/user/index.vue'),
  },
  {
    path: '/info/index',
    name: 'info_index',
    component: () => import('../views/info/index.vue'),
    meta: {
      title: '信息'
    }
  },
  {
    path: '/contact/index',
    name: 'contact',
    component: () => import('../views/contact/index.vue'),
    meta: {
      title: '通讯录'
    }
  },
  {
    path: '/zhuanqian/index',
    name: 'zhuanqian',
    component: () => import('../views/zhuanqian/index.vue'),
    meta: {
      title: '发现'
    }
  },
  {
    path: '/contact/custom',
    name: 'custom',
    component: () => import('../views/contact/custom.vue'),
    meta: {
      title: '客服列表'
    }
  },
  {
    path: '/contact/custom_detail',
    name: 'custom_detail',
    component: () => import('../views/contact/custom_detail.vue'),
    meta: {
      title: '客服详情'
    }
  },
  {
    path: '/contact/link',
    name: 'link',
    component: () => import('../views/contact/link.vue'),
    meta: {
      title: '常用链接'
    }
  },
  {
    path: '/chat/index',
    name: 'chat',
    component: () => import('../views/chat/index.vue'),
    meta: {
      title: '聊天'
    }
  },
  {
    path: '/chat/info',
    name: 'chatinfo',
    component: () => import('../views/chat/info.vue'),
    meta: {
      title: '聊天信息'
    }
  },
  {
    path: '/chat/send_packet',
    name: 'chat_send_packet',
    component: () => import('../views/chat/send_packet.vue'),
    meta: {
      title: '发红包'
    }
  },
  {
    path: '/chat/packet_log',
    name: 'chat_packet_log',
    component: () => import('../views/chat/packet_log.vue'),
    meta: {
      title: '红包详情'
    }
  },
  {
    path: '/contact/new_friends',
    name: 'new_friends',
    component: () => import('../views/contact/new_friends.vue'),
    meta: {
      title: '新朋友'
    }
  },
  {
    path: '/message/index',
    name: 'message',
    component: () => import('../views/message/index.vue'),
    meta: {
      title: '消息助手'
    }
  },
  {
    path: '/friend/find',
    name: 'find',
    component: () => import('../views/friend/find.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/friend/info',
    name: 'friend_info',
    component: () => import('../views/friend/info.vue'),
    meta: {
      title: '用户信息'
    }
  },
  {
    path: '/friend/set_name',
    name: 'set_name',
    component: () => import('../views/friend/set_name.vue'),
    meta: {
      title: '设置备注'
    }
  },
  {
    path: '/friend/apply',
    name: 'apply',
    component: () => import('../views/friend/apply.vue'),
    meta: {
      title: '添加好友'
    }
  },
  {
    path: '/group/group_list',
    name: 'group_list',
    component: () => import('../views/group/group_list.vue'),
    meta: {
      title: '群组列表'
    }
  },
  {
    path: '/group/edit',
    name: 'edit',
    component: () => import('../views/group/edit.vue'),
    meta: {
      title: '创建群组'
    }
  },
  {
    path: '/group/group_sending',
    name: 'group_sending',
    component: () => import('../views/group/group_sending.vue'),
    meta: {
      title: '选择群组或好友'
    }
  },
  {
    path: '/group/index',
    name: 'group_index',
    component: () => import('../views/group/index.vue'),
    meta: {
      title: '群组聊天'
    }
  },
  {
    path: '/group/info',
    name: 'group_info',
    component: () => import('../views/group/info.vue'),
    meta: {
      title: '聊天信息'
    }
  },
  {
    path: '/group/remarks',
    name: 'remarks',
    component: () => import('../views/group/remarks.vue'),
    meta: {
      title: '聊天信息'
    }
  },
  {
    path: '/group/nickname',
    name: 'group_nickname',
    component: () => import('../views/group/nickname.vue'),
    meta: {
      title: '我在群里的昵称'
    }
  },
  {
    path: '/group/add',
    name: 'add',
    component: () => import('../views/group/add.vue'),
    meta: {
      title: '选择联系人'
    }
  },
  {
    path: '/group/add_admin',
    name: 'add_admin',
    component: () => import('../views/group/add_admin.vue'),
    meta: {
      title: '聊天成员'
    }
  },
  {
    path: '/group/group_admin',
    name: 'group_admin',
    component: () => import('../views/group/group_admin.vue'),
    meta: {
      title: '管理员列表'
    }
  },
  {
    path: '/group/jinyans',
    name: 'jinyans',
    component: () => import('../views/group/jinyans.vue'),
    meta: {
      title: '成员禁言'
    }
  },
  {
    path: '/group/member',
    name: 'member',
    component: () => import('../views/group/member.vue'),
    meta: {
      title: '全部成员'
    }
  },
  {
    path: '/group/name',
    name: 'name',
    component: () => import('../views/group/name.vue'),
    meta: {
      title: '修改群聊名称'
    }
  },
  {
    path: '/group/notice',
    name: 'notice',
    component: () => import('../views/group/notice.vue'),
    meta: {
      title: '修改公告'
    }
  },
  {
    path: '/group/push_group',
    name: 'push_group',
    component: () => import('../views/group/push_group.vue'),
    meta: {
      title: '选择群组或好友'
    }
  },
  {
    path: '/group/qrcode',
    name: 'qrcode',
    component: () => import('../views/group/qrcode.vue'),
    meta: {
      title: '群二维码'
    }
  },
  {
    path: '/group/reduce',
    name: 'reduce',
    component: () => import('../views/group/reduce.vue'),
    meta: {
      title: '聊天成员'
    }
  },
  {
    path: '/group/reduce_admin',
    name: 'reduce_admin',
    component: () => import('../views/group/reduce_admin.vue'),
    meta: {
      title: '管理员列表'
    }
  },
  {
    path: '/group/send_packet',
    name: 'send_packet',
    component: () => import('../views/group/send_packet.vue'),
    meta: {
      title: '发红包'
    }
  },
  {
    path: '/group/send_packet2',
    name: 'send_packet2',
    component: () => import('../views/group/send_packet2.vue'),
    meta: {
      title: '发红包'
    }
  },
  {
    path: '/group/send_packet3',
    name: 'send_packet3',
    component: () => import('../views/group/send_packet3.vue'),
    meta: {
      title: '发红包'
    }
  },
  {
    path: '/group/send_packet4',
    name: 'send_packet4',
    component: () => import('../views/group/send_packet4.vue'),
    meta: {
      title: '发红包'
    }
  },
  {
    path: '/friend/accept',
    name: 'accept',
    component: () => import('../views/friend/accept.vue'),
    meta: {
      title: '通过好友验证'
    }
  },
  {
    path: '/user/wallet',
    name: 'wallet',
    component: () => import('../views/user/wallet.vue'),
  },
  {
    path: '/user/editPayPassword',
    name: 'editPayPassword',
    component: () => import('../views/user/editPayPassword.vue'),
    meta: {
      title: '修改支付密码'
    }
  },
  {
    path: '/user/editPwd',
    name: 'editPwd',
    component: () => import('../views/user/editPwd.vue'),
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/user/touzhu',
    name: 'touzhu',
    component: () => import('../views/user/touzhu.vue'),
    meta: {
      title: '游戏记录'
    }
  },
  {
    path: '/user/solitaire',
    name: 'solitaire',
    component: () => import('../views/user/solitaire.vue'),
    meta: {
      title: '游戏记录'
    }
  },
  {
    path: '/user/niuniu',
    name: 'niuniu',
    component: () => import('../views/user/niuniu.vue'),
    meta: {
      title: '游戏记录'
    }
  },
  {
    path: '/user/mine',
    name: 'mine',
    component: () => import('../views/user/mine.vue'),
    meta: {
      title: '游戏记录'
    }
  },
  {
    path: '/user/leyou',
    name: 'user_leyou',
    component: () => import('../views/user/leyou.vue'),
    meta: {
      title: '游戏记录'
    }
  },
  {
    path: '/user/backwater',
    name: 'backwater',
    component: () => import('../views/user/backwater.vue'),
    meta: {
      title: '反水记录'
    }
  },
  {
    path: '/user/recharge',
    name: 'recharge',
    component: () => import('../views/user/recharge.vue'),
    meta: {
      title: '充值记录'
    }
  },
  {
    path: '/user/withdrawal',
    name: 'withdrawal',
    component: () => import('../views/user/withdrawal.vue'),
    meta: {
      title: '提现记录'
    }
  },
  {
    path: '/user/subordinate',
    name: 'subordinate',
    component: () => import('../views/user/subordinate.vue'),
    meta: {
      title: '下线列表'
    }
  },
  {
    path: '/user/customer_touzhu',
    name: 'customer_touzhu',
    component: () => import('../views/user/customer_touzhu.vue'),
    meta: {
      title: '下单记录'
    }
  },
  {
    path: '/user/customer_orders',
    name: 'customer_orders',
    component: () => import('../views/user/customer_orders.vue'),
    meta: {
      title: '下线明细'
    }
  },
  {
    path: '/user/addUser',
    name: 'addUser',
    component: () => import('../views/user/addUser.vue'),
    meta: {
      title: '开户'
    }
  },
  {
    path: '/question/index',
    name: 'question_index',
    component: () => import('../views/question/index.vue'),
    meta: {
      title: '常见问题'
    }
  },
  {
    path: '/question/detail',
    name: 'inddetailex',
    component: () => import('../views/question/detail.vue'),
    meta: {
      title: '问题详情'
    }
  },
  {
    path: '/user/info',
    name: 'user_info',
    component: () => import('../views/user/info.vue'),
  },
  {
    path: '/user/addBank',
    name: 'addBank',
    component: () => import('../views/user/addBank.vue'),
  },
  {
    path: '/user/redpackhistory',
    name: 'redpackhistory',
    component: () => import('../views/user/redpackhistory.vue'),
    meta: {
      title: '红包记录'
    }
  },
  {
    path: '/user/packet_log',
    name: 'user_packet_log',
    component: () => import('../views/user/packet_log.vue'),
    meta: {
      title: '红包详情'
    }
  },
  {
    path: '/pay/pay',
    name: 'pay',
    component: () => import('../views/pay/pay.vue'),
  },
  {
    path: '/pay/cashout',
    name: 'pay_cashout',
    component: () => import('../views/pay/cashout.vue'),
  },
  {
    path: '/pay/cashout_agent',
    name: 'cashout_agent',
    component: () => import('../views/pay/cashout_agent.vue'),
  },
  {
    path: '/pay/cashout_default',
    name: 'cashout_default',
    component: () => import('../views/pay/cashout_default.vue'),
  },
  {
    path: '/pay/cashout_c2clog',
    name: 'cashout_c2clog',
    component: () => import('../views/pay/cashout_c2clog.vue'),
  },
  {
    path: '/pay/cashout_c2c_list',
    name: 'cashout_c2c_list',
    component: () => import('../views/pay/cashout_c2c_list.vue'),
  },
  {
    path: '/pay/pay_c2c_add',
    name: 'pay_c2c_add',
    component: () => import('../views/pay/pay_c2c_add.vue'),
  },
  {
    path: '/pay/pay_c2c_order',
    name: 'pay_c2c_order',
    component: () => import('../views/pay/pay_c2c_order.vue'),
  },
  {
    path: '/pay/pay_c2c_money',
    name: 'pay_c2c_money',
    component: () => import('../views/pay/pay_c2c_money.vue'),
  },
  {
    path: '/pay/pay_agent',
    name: 'pay_agent',
    component: () => import('../views/pay/pay_agent.vue'),
  },
  {
    path: '/pay/pay_c2c',
    name: 'pay_c2c',
    component: () => import('../views/pay/pay_c2c.vue'),
  },
  {
    path: '/pay/pay_c2clog',
    name: 'pay_c2clog',
    component: () => import('../views/pay/pay_c2clog.vue'),
  },
  {
    path: '/pay/pay_gateway',
    name: 'pay_gateway',
    component: () => import('../views/pay/pay_gateway.vue'),
  },
  {
    path: '/pay/pay_qrcode',
    name: 'pay_qrcode',
    component: () => import('../views/pay/pay_qrcode.vue'),
  },
  {
    path: '/pay/pay_third',
    name: 'pay_third',
    component: () => import('../views/pay/pay_third.vue'),
  },
  {
    path: '/pay/pay_wanbi',
    name: 'pay_wanbi',
    component: () => import('../views/pay/pay_wanbi.vue'),
  },
  {
    path: '/pay/pay_kdpay',
    name: 'pay_kdpay',
    component: () => import('../views/pay/pay_kdpay.vue'),
  },
  {
    path: '/pay/pay_jdpay',
    name: 'pay_jdpay',
    component: () => import('../views/pay/pay_jdpay.vue'),
  },
  {
    path: '/pay/pay_yizhifu',
    name: 'pay_yizhifu',
    component: () => import('../views/pay/pay_yizhifu.vue'),
  },
  {
    path: '/pay/pay_qpay',
    name: 'pay_qpay',
    component: () => import('../views/pay/pay_qpay.vue'),
  },
  {
    path: '/pay/proceeds',
    name: 'proceeds',
    component: () => import('../views/pay/proceeds.vue'),
  },
  {
    path: '/user/editBank',
    name: 'editBank',
    component: () => import('../views/user/editBank.vue'),
  },
  {
    path: '/user/nickname',
    name: 'user_nickname',
    component: () => import('../views/user/nickname.vue'),
  },
  {
    path: '/user/share',
    name: 'share',
    component: () => import('../views/user/share.vue'),
    meta: {
      title: '分享',
    },
  },
  {
    path: '/user/redpacklog',
    name: 'redpacklog',
    component: () => import('../views/user/redpacklog.vue'),
    meta: {
      title: '分享',
    },
  },
  {
    path: '/user/customer_order_detail',
    name: 'customer_order_detail',
    component: () => import('../views/user/customer_order_detail.vue'),
    meta: {
      title: '个人信息',
    },
  },
  {
    path: '/user/verify_user',
    name: 'verify_user',
    component: () => import('../views/user/verify_user.vue'),
    meta: {
      title: '账号验证',
    },
  },
  {
    path: '/user/cashout',
    name: 'user_cashout',
    component: () => import('../views/user/cashout.vue'),
    meta: {
      title: '提现',
    },
  },
  {
    path: '/zhuanqian/predict',
    name: 'predict',
    component: () => import('../views/zhuanqian/predict.vue'),
    meta: {
      title: '开奖预测'
    }
  },
  {
    path: '/zhuanqian/predict_detail',
    name: 'predict_detail',
    component: () => import('../views/zhuanqian/predict_detail.vue'),
    meta: {
      title: '预测详情'
    }
  },
  {
    path: '/zhuanqian/kaijiang',
    name: 'kaijiang',
    component: () => import('../views/zhuanqian/kaijiang.vue'),
    meta: {
      title: '历史开奖'
    }
  },
  {
    path: '/zhuanqian/trend',
    name: 'trend',
    component: () => import('../views/zhuanqian/trend.vue'),
    meta: {
      title: '走势图表'
    }
  },
  {
    path: '/zhuanqian/game',
    name: 'game',
    component: () => import('../views/zhuanqian/game.vue'),
    meta: {
      title: '杀号定胆'
    }
  },
  {
    path: '/zhuanqian/game_position',
    name: 'game_position',
    component: () => import('../views/zhuanqian/game_position.vue'),
    meta: {
      title: '定位杀号'
    }
  },
  {
    path: '/zhuanqian/dream',
    name: 'dream',
    component: () => import('../views/zhuanqian/dream.vue'),
    meta: {
      title: '解梦'
    }
  },
  {
    path: '/zhuanqian/page',
    name: 'zhuanqian_page',
    component: () => import('../views/zhuanqian/page.vue'),
    meta: {
      title: '发现'
    }
  },
  {
    path: '/picture/index',
    name: 'picture_index',
    component: () => import('../views/picture/index.vue'),
    meta: {
      title: '图库'
    }
  },
  {
    path: '/picture/detail',
    name: 'picture_detail',
    component: () => import('../views/picture/detail.vue'),
    meta: {
      title: '图库详情'
    }
  },
  {
    path: '/picture/search',
    name: 'picture_search',
    component: () => import('../views/picture/search.vue'),
    meta: {
      title: '图库搜索'
    }
  },
  {
    path: '/picture/live',
    name: 'picture_live',
    component: () => import('../views/picture/live.vue'),
    meta: {
      title: '开奖直播'
    }
  },
  {
    path: '/picture/live_back',
    name: 'picture_live_back',
    component: () => import('../views/picture/live_back.vue'),
    meta: {
      title: '直播回放'
    }
  },
  {
    path: '/picture/live_detail',
    name: 'picture_live_detail',
    component: () => import('../views/picture/live_detail.vue'),
    meta: {
      title: '直播详情'
    }
  },
  {
    path: '/picture/lottery',
    name: 'picture_lottery',
    component: () => import('../views/picture/lottery.vue'),
    meta: {
      title: '开奖历史'
    }
  },
  {
    path: '/picture/date',
    name: 'picture_date',
    component: () => import('../views/picture/date.vue'),
    meta: {
      title: '开奖日期'
    }
  },
  {
    path: '/chat/note',
    name: 'chat_note',
    component: () => import('../views/chat/note.vue'),
    meta: {
      title: '日记详情'
    }
  },
  {
    path: '/group/note',
    name: 'group_note',
    component: () => import('../views/group/note.vue'),
    meta: {
      title: '日记详情'
    }
  },
  {
    path: '/group/log',
    name: 'group_log',
    component: () => import('../views/group/log.vue'),
    meta: {
      title: '游戏记录'
    }
  },
  {
    path: '/group/live',
    name: 'group_live',
    component: () => import('../views/group/live.vue'),
    meta: {
      title: '群直播'
    }
  },
  {
    path: '/user/account',
    name: 'user_account',
    component: () => import('../views/user/account.vue'),
    meta: {
      title: '钱包明细'
    }
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('../views/customer/index.vue'),
    meta: {
      title: '选择客服'
    }
  },
  {
    path: '/customer/go',
    name: 'customer_go',
    component: () => import('../views/customer/go.vue'),
    meta: {
      title: '在线客服'
    }
  },
  {
    path: '/customer/chat',
    name: 'customer_chat',
    component: () => import('../views/customer/chat.vue'),
    meta: {
      title: '在线客服'
    }
  },
  {
    path: '/guidance',
    name: 'guidance',
    component: () => import('../views/guidance/index.vue'),
    meta: {
      title: '落地页'
    }
  },
  {
    path: '/leyou',
    name: 'leyou',
    component: () => import('../views/zhuanqian/leyou.vue'),
    meta: {
      title: '乐游棋牌'
    }
  },
  {
    path: '/sideline',
    name: 'sideline',
    component: () => import('../views/sideline/index.vue'),
    meta: {
      title: '网上赚钱'
    }
  },
  {
    path: '/sideline/detail',
    name: 'sideline_detail',
    component: () => import('../views/sideline/detail.vue'),
    meta: {
      title: '任务详情'
    }
  },
  {
    path: '/sideline/post',
    name: 'sideline_post',
    component: () => import('../views/sideline/post.vue'),
    meta: {
      title: '发布任务'
    }
  },
  {
    path: '/user/sideline/my',
    name: 'sideline_my',
    component: () => import('../views/user/sideline/my.vue'),
    meta: {
      title: '我发布的任务'
    }
  },
  {
    path: '/user/sideline/review',
    name: 'sideline_review',
    component: () => import('../views/user/sideline/review.vue'),
    meta: {
      title: '审核任务'
    }
  },
  {
    path: '/user/sideline/apply',
    name: 'sideline_apply',
    component: () => import('../views/user/sideline/apply.vue'),
    meta: {
      title: '我报名的任务'
    }
  },
  {
    path: '/user/sideline/collect',
    name: 'sideline_collect',
    component: () => import('../views/user/sideline/collect.vue'),
    meta: {
      title: '我收藏的任务'
    }
  },
  {
    path: '/sideline/submit',
    name: 'sideline_submit',
    component: () => import('../views/sideline/submit.vue'),
    meta: {
      title: '提交任务'
    }
  },
  {
    path: '/chat/hs',
    name: 'hslogin',
    component: () => import('../views/user/hslogin.vue'),
    meta: {
      title: '登录中'
    }
  },
  {
    path: '/demand',
    name: 'demand',
    component: () => import('../views/demand/index.vue'),
    meta: {
      title: '供需版块'
    }
  },
  {
    path: '/demand/detail',
    name: 'demand_detail',
    component: () => import('../views/demand/detail.vue'),
    meta: {
      title: '供需详情'
    }
  },
  {
    path: '/demand/post',
    name: 'demand_post',
    component: () => import('../views/demand/post.vue'),
    meta: {
      title: '发布需求'
    }
  },
  {
    path: '/user/demand',
    name: 'user_demand',
    component: () => import('../views/user/demand.vue'),
    meta: {
      title: '我的需求'
    }
  },
  {
    path: '/user/database',
    name: 'database_index',
    component: () => import('../views/user/database/index.vue'),
    meta: {
      title: '我的稿件'
    }
  },
  {
    path: '/user/database/post',
    name: 'database_post',
    component: () => import('../views/user/database/post.vue'),
    meta: {
      title: '投稿'
    }
  },
  {
    path: '/chat/video',
    name: 'chat_video',
    component: () => import('../views/chat/video.vue'),
    meta: {
      title: '视频通话'
    }
  },
  {
    path: '/chat/audio',
    name: 'chat_audio',
    component: () => import('../views/chat/audio.vue'),
    meta: {
      title: '语音通话'
    }
  },
  {
    path: '/forum',
    name: 'forum',
    component: () => import('../views/forum/index.vue'),
    meta: {
      title: '讲堂视频'
    }
  },
  {
    path: '/forum/detail',
    name: 'forum_detail',
    component: () => import('../views/forum/detail.vue'),
    meta: {
      title: '视频详情'
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


export default router
